/** Low level form styling - not specific to a given page or component
 *  ------------------------------------------------------------------------------------------------
 *  select styling techniques from: https://www.filamentgroup.com/lab/select-css.html
**/

.form {

  & input,
  & select,
  & textarea {
    display: block;
    width: 100%;
    min-width: 0;
    padding: var(--bsu-xs) var(--bsu-s);
    background: transparent;
    border: 0;
    border-bottom: 2px solid;
    color: currentColor;
    appearance: none;
  }

  & select {
    cursor: pointer;
  }

  & select::-ms-expand {
    display: none;
  }

  & select:focus {
    outline: dashed 0.2rem currentColor;
    outline-offset: 0.2rem;
  }

  & select option {
    font-weight: normal;
  }

  & textarea {
    resize: vertical;
    min-height: calc((var(--bsu-xs) * 2) + (1rem * 1.5 * 4)); /* four lines */
  }
}

.form__input-group {

  & label {
    display: block;
    font-weight: 700;
  }

  & select {
    font-weight: 700;
  }

  & label + input,
  & label + textarea,
  & label + select {
    margin-top: var(--bsu-s);
  }
}

/* integrated labels */
.form__input-group--integrated {
  display: flex;
  align-items: flex-end; /* align form items to end */
  padding-left: var(--bsu-xs);

  & input,
  & select,
  & label {
    display: block;
    padding: var(--bsu-xs) var(--bsu-s);
    background: transparent;
    margin: 0; /* safari has 2px margins under inputs? */
    border: 0;
    border-bottom: 2px solid;
    line-height: 1.5;
  }

  & label {
    border-right: 0;
    padding-right: 0;
    font-weight: 700;
    white-space: nowrap;
  }

  & select {
    font-weight: 700;
  }

  & select option {
    font-weight: normal;
  }

  & input,
  & select {
    border-left: 0;
    flex: 1;
  }
}

.form__text {
  padding: 0 var(--bsu);
  font-size: 0.9em;
}

.form__input-group--checkbox {
  padding-left: var(--bsu-l);
  display: block;
  position: relative;

  & input {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    white-space: nowrap;
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
  }

  & input:not(:checked) + label::before,
  & input:checked + label::before {
    content: '';
    position: absolute;
    left: var(--bsu-s);
    top: 2px;
    width: var(--s1);
    height: var(--s1);
    border: 2px solid;
    background: transparent;
    border-radius: var(--radius-default);
  }

  /* checked mark aspect */
  & input:not(:checked) + label::after,
  & input:checked + label::after {
    content: '';
    position: absolute;
    top: 6px;
    left: calc(var(--bsu-s) + 4px);
    width: calc(var(--s1) - 8px);
    height: calc(var(--s1) - 8px);
    background: currentColor;
    border-radius: 100%;
    transition: all var(--time-transition) var(--ease-default);
    transition-property: opacity;
  }

  /* checked mark aspect changes */
  & input:not(:checked) + label::after {
    opacity: 0;
  }

  & input:checked + label::after {
    opacity: 1;
  }

  /* Disabled checkbox - not used here */
  /* & input:disabled:not(:checked) + label::before,
  & input:disabled:checked + label::before {
    box-shadow: none;
    border-color: #bbb;
    background-color: #e9e9e9;
  }

  & input:disabled:checked + label::after {
    color: #777;
  }

  & input:disabled + label {
    color: #aaa;
  } */

  /* Accessibility */
  & input:checked:focus + label::before,
  & input:not(:checked):focus + label::before {
    outline: dashed 0.2rem currentColor;
    outline-offset: 0.2rem;
  }
}

.form__input-group--integrated.form__input-group--select {

  & label {
    padding-right: calc(var(--bsu-s) * 0.5);
  }

  & select {
    padding-left: calc(var(--bsu-s) * 0.5);
  }
}

.form__input-group--integrated-textarea {
  padding-left: var(--bsu-xs);

  & textarea,
  & label {
    display: block;
    flex: 1;
    padding: var(--bsu-xs) var(--bsu-s);
    background: transparent;
    border: 0;
    border-bottom: 2px solid;
    line-height: 1.5;
  }

  & label {
    border-bottom: 0;
    padding-bottom: 0;
    font-weight: 700;
  }

  & textarea {
    border-top: 0;
  }
}

.form__input-group--select {
  position: relative;

  &::after {
    content: ' ';
    position: absolute;
    right: var(--bsu-xs);
    top: 50%;
    margin-top: calc(var(--bsu-xs) * -0.5);
    width: var(--bsu-xs);
    height: var(--bsu-xs);
    background: currentColor;
    border-radius: 0.2rem;
    pointer-events: none;

    /*
      rounded triangle with clip-path
      clip-path + rotate to put rounded edge at the bottom
      see: https://bennettfeely.com/clippy/
      browsers without clip-path get rounded rhombus or square which also looks fine...
      could use @supports if needed
    */
    clip-path: polygon(10% 0, 100% 90%, 100% 100%, 0 100%, 0 0);
    transform: rotate(-45deg);
  }
}

/** trailing icons and buttons
 *  ------------------------------------------------------------------------------------------------
**/
.form__input-group__icon {
  display: block;
  flex: 0;
  border-bottom: 2px solid;

  & button {
    background: transparent;
    border: 0;
    outline: 0;
    padding: 0;
    color: currentColor;
    cursor: pointer;
  }

  & .form__input-group__icon-content {
    display: block;
    padding: var(--bsu-xs) var(--bsu-s);
    background: transparent;
    border: 0;
    line-height: 1.5;
  }

  & .SvgWrap {
    width: 1rem;
    margin-top: 0.25rem; /* svg aren't subject to lineheight. this lines it up */
  }

  & path,
  & svg {
    fill: currentColor;
  }

  & button:focus {
    outline: dashed 0.2rem currentColor;
    outline-offset: 0.2rem;
  }
}

/** multiple inputs
 *  ------------------------------------------------------------------------------------------------
 *  uses our utility class .u-stacks
 *  see the css file for utilities/stacks.css for more detail!
**/
.form__split-inputs {
  /*
    before this container width all children will stack
    - play with the value to find something that looks best!
  */
  --stack-before: 30rem;
}

.form__split-inputs--submits {
  justify-content: flex-start;
}


/** submits
 *  ------------------------------------------------------------------------------------------------
**/
.form__submit {
  /* tone down CTA in submit areas */
  & .CTA .CTA__a {
    font-size: 0.9em;
  }

  & .CTA {
    display: inline-block;
    flex: 0;
    white-space: nowrap;
    position: relative;
  }

  & .CTA .Wrap {
    padding: 0;
  }
}

.form .CTA .Wrap,
.form--with-sections .CTA .Wrap {
  /* ctas in general forms - line up with inputs */
  padding: 0 var(--bsu-xs);
}


/** JS styles
 *  ------------------------------------------------------------------------------------------------
**/
.js--validate-me {

  & button[type='submit'] {
    position: relative;

    &::after {
      content: 'All form fields are required';
      font-family: var(--ff-body);
      font-weight: normal;
      font-size: 0.8em;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      opacity: 0;
      transition: opacity var(--time-transition) var(--ease-default);
      margin-top: var(--bsu-s);

      /* margin-top: calc(var(--bsu-s) * -1); *//* pull up against button margin... */
    }

    & .CTA__text::before {
      content: ' ';
      position: absolute;
      opacity: 0;
      top: 50%;
      left: -15%;
      background: currentColor;
      height: 2px;
      width: 130%;
      transform: scaleX(0);
      transition: transform 200ms ease, opacity 200ms ease;
    }
  }
}

.js--validate-me--invalid {

  & button[type='submit'] {
    pointer-events: none;
    /* stylelint-disable-next-line declaration-no-important */
    background: transparent !important;

    /* opacity: 0.75; */
    /* margin-bottom: var(--bsu-s); */

    & span {
      /* stylelint-disable-next-line declaration-no-important */
      color: currentColor !important;
    }

    & .CTA__text::before {
      transform: scaleX(1);
      opacity: 0.3;
    }

    &::after {
      opacity: 1;
    }
  }
}

/** forms with sections and titles
 *  ------------------------------------------------------------------------------------------------
**/
.form--with-sections .Wrap {
  padding: 0;
}


.form__section {
  padding: var(--bsu-xs) var(--bsu);
}

.form__section-title {
  position: relative;

  /* left: calc(var(--bsu-s) * -1); */
}

.form__section-instructions {
  font-size: var(--s-1);
  position: relative;
  opacity: 0.9;

  /* left: calc(var(--bsu-s) * -1); */
}

.form__section-title + .form__input-group--integrated,
.form__section-title + .form__input-group--integrated-textarea,
.form__section-instructions + .form__input-group--integrated,
.form__section-instructions + .form__input-group--integrated-textarea {
  --flow-space: var(--bsu-s);
}

.form__section-title + .form__section-instructions {
  --flow-space: var(--bsu-xs);
}
