/** Rules for embedded elements
 *  ------------------------------------------------------------------------------------------------
 *  Taken from CSS Remedy : https://github.com/mozdevs/cssremedy
**/

/*
* 1. Block display is usually what we want
* 2. Remove strange space-below when inline
* 3. Responsive by default
*/
img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
  max-width: 100%;
}

/*
* Maintain intrinsic aspect ratios when `max-width` is applied
* (iframe, embed, and object have no intrinsic ratio, set height explicitly)
*/
img,
svg,
video,
canvas {
  height: auto;
}

/*
* There is no good reason elements default to 300px,
* and audio files are unlikely to come with a width attribute
*/
audio {
  width: 100%;
}
