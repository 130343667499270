/** SiteHeader
 *  ------------------------------------------------------------------------------------------------
**/
.SiteHeader {
  /* padding comes from internal elements */
  padding: var(--bsu-s) 0;
  z-index: var(--z-fixed-header);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--colour-background-body);

  /* box-shadow: 0 2px 3px 0 var(--colour-background-body); */
  /* color: currentColor; */

  & svg path {
    fill: currentColor;
  }
}


.body--with-colour-background .SiteHeader {
  /* background-color: currentColor; */ /* now handled by use-background--background class */

  /* box-shadow: 0 2px 3px 0 currentColor; */
}

/* home only */
.no-js .always-colour-background .SiteHeader,
.no-intersection .always-colour-background .SiteHeader {
  background-color: var(--colour-background-body);
  color: var(--colour-primary);
}

@media (--column-break) {

  .SiteHeader {
    padding: 0 var(--bsu) var(--bsu-s);
    position: fixed;
    position: sticky;

    & .Wrap {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  /* Blur effect - disabled for now... */
  /* @supports (backdrop-filter: blur(5px)) {

    .js .SiteHeader {
      backdrop-filter: blur(5px);
      box-shadow: 0 2px 3px 0 var(--colour-background-body);
      background-color: transparent;
    }

    .js .body--with-colour-background .SiteHeader {
      box-shadow: 0 2px 3px 0 currentColor;
      background-color: transparent;
    }
  } */
}


/** Header logo
 *  ------------------------------------------------------------------------------------------------
 *  GLADD logo
 *  on larger screens this is positioned off to the side to line up with vertical headers
**/
.SiteHeader__logo {
  font-weight: bold;
  color: currentColor;
  letter-spacing: 2px;
  position: relative;
  font-size: 1.4rem;
  z-index: var(--z-fixed-header-nav-toggle);
  display: flex;
  align-items: center;
  justify-content: space-between;

  & a {
    color: inherit;
  }
}

.SiteHeader__logos-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  opacity: 1;
  transform: translateY(0);
  transition: all var(--time-transition) var(--ease-default);
  transition-property: opacity, transform;
}

.body-surrogate.colour--homesplash .SiteHeader__logos-wrap {
  opacity: 0;
  transform: translateY(var(--bsu));
}

@media (--column-break) {

  .SiteHeader__logo {
    padding: var(--bsu-s) 0 0;

    & .js--nav-toggle {
      display: none;
    }
  }
}


/** navigation wrap
 *  ------------------------------------------------------------------------------------------------
 *  used to help position the two menus and central logo
**/
.SiteHeader__navigation-scroll-wrap {
  z-index: var(--z-fixed-nav);
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  /* visually hidden */
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  max-height: 1px;
  white-space: nowrap;
  max-width: 1px;
  overflow: hidden;
}

.SiteHeader__navigation {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  padding: var(--bsu-l) var(--bsu);
  position: relative;
  z-index: 2;
  overflow: hidden;
}

/* visible when activated */
.SiteHeader__navigation-scroll-wrap.visible {
  clip: auto;
  clip-path: none;
  max-height: none;
  max-width: none;
  white-space: normal;
  overflow: auto;
  overflow-x: hidden;
}

/* js specific nav backgrounds */
.js .SiteHeader__navigation::before,
.js .SiteHeader__navigation::after {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  background:
    linear-gradient(
      to bottom,
      var(--colour-background-pink),
      var(--colour-background-pink) 16.66%,
      transparent 16.66%,
      transparent 33.32%,
      var(--colour-background-purple) 33.32%,
      var(--colour-background-purple) 50%,
      transparent 50%,
      transparent 66.66%,
      var(--colour-background-green) 66.66%,
      var(--colour-background-green) 83.3%,
      transparent 83.3%,
      transparent 100%
    );
  background-size: 100% 100%;
  transition: transform var(--time-transition) var(--time-transition) var(--ease-default);
  transform: translateX(-100%);
}

.js .SiteHeader__navigation::after {
  background:
    linear-gradient(
      to bottom,
      transparent,
      transparent 16.66%,
      var(--colour-background-teal) 16.66%,
      var(--colour-background-teal) 33.32%,
      transparent 33.32%,
      transparent 50%,
      var(--colour-background-yellow) 50%,
      var(--colour-background-yellow) 66.66%,
      transparent 66.66%,
      transparent 83.3%,
      var(--colour-background-red) 83.3%,
      var(--colour-background-red) 100%
    );
  background-size: 100% 100%;
  transform: translateX(100%);
}

.js .js--nav-active .SiteHeader__navigation::before,
.js .js--nav-active .SiteHeader__navigation::after {
  transform: translateX(0);
  transition: transform var(--time-transition) var(--ease-default);
}

/* without js we use the home page splash screen as a nav fallback */
.no-js .SiteHeader__navigation-scroll-wrap {
  display: none;
}

@media (--column-break) {

  .SiteHeader__navigation-scroll-wrap {
    /* undo hiding */
    clip: auto;
    clip-path: none;
    max-height: none;
    max-width: none;
    white-space: normal;
    overflow: auto;
    position: static;
    height: auto;
    width: auto;
    flex-grow: 1;
  }

  .SiteHeader__navigation {
    display: flex;
    flex-direction: row;
    min-height: 0;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    width: 100%;
    min-width: 0;
    padding: 0;
  }

  .no-js .SiteHeader__navigation-scroll-wrap {
    display: flex;
  }

  .js .SiteHeader__navigation::before,
  .js .SiteHeader__navigation::after {
    content: none;
  }
}


/** mobile nav hamburger
 *  ------------------------------------------------------------------------------------------------
**/
.hamburger-box {
  width: var(--s2);
  height: var(--s0);
  display: block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 100%;
  height: 4px;
  background-color: currentColor;
  border-radius: 1px;
  position: absolute;
}

.hamburger-inner::before,
.hamburger-inner::after {
  content: ' ';
  display: block;
}

.hamburger-inner::before {
  top: calc(var(--s0) * 0.5 * -1);
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}

.hamburger-inner::after {
  bottom: calc(var(--s0) * 0.5 * -1);
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger-box .hamburger-inner {
  transition: transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.js--contains-active-nav .hamburger-inner {
  transform: rotate(225deg);
  transition: transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.js--contains-active-nav .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}

.js--contains-active-nav .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}


/** mobile nav background
 *  ------------------------------------------------------------------------------------------------
**/
.js--nav-to-toggle__back {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  height: 200%;
  width: 100%;
  transform: translateY(-50%) scaleY(0);
  background: var(--colour-background-body);

  /*
    opacity is used only to mark the end of transition
    it should complete at the same time as ::before and ::after
  */
  opacity: 0;
  transition:
    transform var(--time-transition) var(--ease-default),
    opacity 0ms var(--time-transition-double) var(--ease-default),
    background var(--time-transition) var(--ease-default);
}

.js .body--with-colour-background.colour--pink .js--nav-to-toggle__back {
  top: calc(16.55% * 0.5);
  background: var(--colour-background-pink);
}

.js .body--with-colour-background.colour--teal .js--nav-to-toggle__back,
.js .body--with-colour-background.colour--blue .js--nav-to-toggle__back {
  top: calc(16.55% * 1.5);
  background: var(--colour-background-teal);
}

.js .body--with-colour-background.colour--purple .js--nav-to-toggle__back {
  top: calc(16.55% * 2.5);
  background: var(--colour-background-purple);
}

.js .body--with-colour-background.colour--yellow .js--nav-to-toggle__back {
  top: calc(16.55% * 3.5);
  background: var(--colour-background-yellow);
}

.js .body--with-colour-background.colour--green .js--nav-to-toggle__back {
  top: calc(16.55% * 4.5);
  background: var(--colour-background-green);
}

.js .body--with-colour-background.colour--red .js--nav-to-toggle__back {
  top: calc(16.55% * 5.5);
  background: var(--colour-background-red);
}

.js .js--nav-active .js--nav-to-toggle__back {
  transform: translateY(-50%) scaleY(1);
  opacity: 1;
  transition:
    transform var(--time-transition) var(--time-transition) var(--ease-default),
    background var(--time-transition) var(--ease-default);
}

.no-js .js--nav-to-toggle__back-wrap {
  display: none;
}

@media (--column-break) {

  .js--nav-to-toggle__back-wrap {
    display: none;
  }
}


/** Nav and login nav
 *  ------------------------------------------------------------------------------------------------
**/
.SiteHeader__nav,
.SiteHeader__login-nav {
  display: block;
  font-size: var(--s0);
  margin-top: 0;

  /* for nav toggles */
  position: relative;
  z-index: 3;
  opacity: 0;
  transition:
    opacity var(--time-transition) var(--ease-default),
    color var(--time-transition) var(--ease-default);

  & ul {
    justify-content: flex-start;
  }

  & li {
    display: block;
    text-align: center;
    width: 100%;
  }

  & a,
  & .MembershipLinks__sub-link {
    color: currentColor;
    display: inline-block;
    position: relative;
    outline: 0;

    &::after {
      content: ' ';
      position: absolute;
      bottom: 0;
      left: 0;
      background: currentColor;
      height: 2px;
      width: 100%;
      transform: scaleX(0);
      transition: transform 200ms ease, opacity 200ms ease;
    }

    &:hover,
    &:focus {
      color: currentColor;

      /* opacity: 0.5; */

      &::after {
        transform: scaleX(1);
        opacity: 1;
      }
    }

    /* &:focus {
      outline: dashed 0.2rem currentColor;
      outline-offset: 0.2rem;
    } */
  }
}

.js--nav-active {

  & .SiteHeader__nav,
  & .SiteHeader__login-nav {
    opacity: 1;
    transition:
      opacity var(--time-transition) var(--time-transition-slow) var(--ease-default),
      color var(--time-transition) var(--ease-default);
  }
}

@media (--column-break) {

  .SiteHeader__nav,
  .SiteHeader__login-nav {
    /*
    nav items are at least 50% of screen - if they get bigger they will wrap rather than shrink
    */
    padding: var(--bsu-s) 0 0 var(--bsu);
    opacity: 1;
    transform: translateY(0);
    transition: all var(--time-transition) var(--ease-default);
    transition-property: opacity, transform, color;

    & ul {
      justify-content: flex-end;

      /* flex-wrap: nowrap; */
    }

    & li {
      width: auto;
    }

    & .js--current-intersection-section {

      &::after {
        transform: scaleX(1);
        opacity: 0.5;
      }
    }
  }

  .SiteHeader__nav {
    font-size: var(--s-1);
    flex: 1 0 auto;
    max-width: 100%;
  }

  .SiteHeader__login-nav {
    flex: 0 0 auto;
  }

  .body-surrogate.colour--homesplash .SiteHeader__nav {
    opacity: 0;
    transform: translateY(var(--bsu));
  }

  .body-surrogate.colour--homesplash .SiteHeader__login-nav {
    opacity: 0;
    transform: translateY(var(--bsu));
  }
}

@media (--princess) {

  .SiteHeader__nav ul {
    --gap: var(--bsu-l);
  }
}


/** login nav specifically
 *  ------------------------------------------------------------------------------------------------
**/
.SiteHeader__login-nav {
  position: relative;
  text-align: center;
  margin-bottom: var(--bsu);

  & .CTA__a {
    padding: var(--bsu-xxs) var(--bsu-s);
    line-height: 1;
    font-size: 0.8em;
    background: transparent;

    &.CTA__a.CTA__a:focus,
    &.CTA__a.CTA__a:hover { /* not ideal specificity but only used here to override colours... */
      background: transparent;

      & span {
        color: currentColor;
      }

      &::after,
      & span::after {
        content: none;
      }
    }
  }

  & .CTA__a:not(.CTA__a:hover),
  & .CTA__a:not(.CTA__a:focus) {

    & span {
      color: currentColor;
    }
  }
}

.MembershipLinks__sub-link {
  background: transparent;
  display: inline-block;
  outline: 0;
  border: 0;
  text-align: center;
  font-size: 0.7em;
  margin-top: var(--bsu-xs);
  cursor: pointer;
}

@media (--column-break) {

  .SiteHeader__login-nav {
    margin: 0;
  }

  .js--MembershipLinks {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .MembershipLinks__sub-link {
    display: block;
    margin: 0 0 0 var(--bsu-s);
    padding: 2px 0;
  }
}


/** Asclepius logo
 *  ------------------------------------------------------------------------------------------------
**/
.asclepius {
  width: 2.5rem;
  margin: 0 auto var(--bsu);

  & a {
    display: block;
    position: relative;
    color: currentColor;

    &::after {
      content: none;
    }
  }

  & path {
    fill: currentColor;
  }

  & .SvgWrap {
    /* position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    margin-top: -69%; magic number - half of the height to width ratio of this logo */
  }
}

.SiteHeader__central-logo {
  display: none;
}

@media (--column-break) {

  .asclepius {
    display: none;
  }

  .SiteHeader__central-logo {
    display: block;
    width: 3.25rem; /* centralised over vertical titles */
    margin: 0 2px 0 0;
  }

  /* .SiteHeader__central-logo {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;

    & .SvgWrap {
      left: -1.25rem;
    }
  } */
}


/** Colour variations for non-transparent header
 *  ------------------------------------------------------------------------------------------------
**/
.SiteHeader--opaque {
  background-color: var(--colour-background-body);
  color: currentColor;
  position: relative;

  & svg path {
    fill: currentColor;
  }
}
