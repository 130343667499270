/** Events
 *  ------------------------------------------------------------------------------------------------
 *  Events largely use pre-existing styles and elements, but there's a few extras.
**/


/* strike through prices that are no longer available */
.Event__price--not-available .HeaderText__pre {
  opacity: 0.6;
  text-decoration: line-through;
}
