/** CTA Buttons
 *  ------------------------------------------------------------------------------------------------
**/

.ContentCollection {
  text-align: left;
  padding: var(--bsu-s) 0;

  & ul {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;

    /* margin: calc(var(--bsu-s) * -1); */
  }
}

.ContentCollection__item {
  display: block;
  width: 25%;
  min-width: 240px;
  flex-grow: 1;
  padding: var(--bsu-s);
}

.ContentCollection__item--with-image a {
  will-change: opacity;
}

.ContentCollection--with-featured .ContentCollection__item--featured {
  width: 50%;
  min-width: 200px;
  flex-grow: 2;
}

.ContentCollection__link {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  height: 100%;
  padding: var(--bsu);

  /* border: 2px solid currentColor; */
  border-radius: var(--bsu-xs);
  position: relative;
  overflow: hidden;
  color: currentColor;
  background: currentColor;
}

.ContentCollection__item-pre-header,
.ContentCollection__item-header,
.ContentCollection__item-description {
  display: block;
  text-align: left;
  width: 100%;
  position: relative;
  z-index: 3;
}

.ContentCollection__item-header {
  display: inline-block;
  position: relative;
  padding-bottom: 3px;
  width: auto;
  font-size: var(--s1);
  text-transform: uppercase;

  &::after {
    content: ' ';
    position: absolute;
    bottom: 0;
    left: 0;
    background: currentColor;
    height: 2px;
    width: 100%;
    transform: scaleX(0);
    transition: transform 200ms ease, opacity 200ms ease;
  }
}


.ContentCollection__item-pre-header {
  font-size: var(--s-2);
  text-transform: uppercase;
}

.ContentCollection__item-description {
  flex: 1; /* grow descriptions if present */
  margin-top: var(--bsu-s);
  font-size: var(--s-2);
  text-align: left;
}

.ContentCollection__link.ContentCollection__link.ContentCollection__link:hover,
.ContentCollection__link.ContentCollection__link.ContentCollection__link:focus {
  background: transparent;

  & .ContentCollection__item-pre-header,
  & .ContentCollection__item-header,
  & .ContentCollection__item-description {
    color: currentColor;
  }

  & .ContentCollection__item-header {

    &::after {
      transform: scaleX(1);
      opacity: 1;
    }
  }
}

.body--with-default-background .ContentCollection__link.ContentCollection__link.ContentCollection__link:hover,
.body--with-default-background .ContentCollection__link.ContentCollection__link.ContentCollection__link:focus {
  color: currentColor;
}


/* only display images if they'll look good! */
.ContentCollection__item-back {
  display: none;
}

@supports (object-fit: cover) and (filter: grayscale(100%)) and (mix-blend-mode: luminosity) {

  .ContentCollection__item-back,
  .ContentCollection__item-back img,
  .ContentCollection__item-back::after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: var(--bsu-xs);
  }

  .ContentCollection__item-back img {
    object-fit: cover;
    filter: grayscale(100%);
    mask-image: linear-gradient(140deg, transparent 0%, rgba(0, 0, 0, 1));
    transition: filter var(--time-transition) var(--ease-default);

    /* will-change: filter; */
  }

  .ContentCollection__item-back {
    opacity: 0.75;
    mix-blend-mode: luminosity;
  }

  .ContentCollection__link:hover,
  .ContentCollection__link:focus {

    & .ContentCollection__item-back img {
      filter: grayscale(0);
    }

    & .ContentCollection__item-back {
      /* mix-blend-mode: normal; */
    }
  }
}


/** external link indicator
 *  ------------------------------------------------------------------------------------------------
**/
.ContentCollection__item__external-indicator {
  border: 0;
  background: transparent;
  color: currentColor;
  cursor: pointer;
  position: absolute;
  pointer-events: none;
  padding: var(--bsu-xxs);
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  z-index: 3;

  & .SvgWrap {
    width: 0.8rem;
    height: 0.8rem;
  }

  & path {
    fill: var(--colour-background-body);
  }

  & span {
    font-size: var(--s-2);
    display: inline-block;
  }
}

.ContentCollection__item__external-indicator__icon {
  display: inline-block;
  background: currentColor;
  border-radius: 100%;
  padding: var(--bsu-xxs);
  margin-left: var(--bsu-xs);
}

.ContentCollection__item--with-image .ContentCollection__item__external-indicator__icon {
  background: transparent;
}

.ContentCollection__item__external-indicator__text {
  opacity: 0;
  transition: all var(--time-transition) var(--ease-default);
  transition-property: opacity;
}

.ContentCollection__link:hover .ContentCollection__item__external-indicator__text,
.ContentCollection__link:focus .ContentCollection__item__external-indicator__text {
  opacity: 1;
}
