/** SiteFooter
 *  ------------------------------------------------------------------------------------------------
**/

.SiteFooter {
  /* the wrap inside already has left/right padding */
  padding: var(--bsu-l) 0 0;
  text-align: center;
  overflow: hidden;
  position: relative;
  background-color: var(--colour-background-subtle);
  transition: all var(--time-transition) var(--ease-default);
  transition-property: color, background-color;

  /* color: var(--colour-subtle);  */

  & svg path {
    /* fill: var(--colour-subtle); */
    transition: fill var(--time-transition) var(--ease-default);
  }

  & a,
  & button {
    color: var(--colour-subtler);
    transition: all var(--time-transition) var(--ease-default);
    transition-property: color;
    position: relative;
    outline: 0;
    padding: 0;
    line-height: 1.8;
    display: inline-block;

    &::after {
      content: ' ';
      position: absolute;
      bottom: 0;
      left: 0;
      background: currentColor;
      height: 1px;
      width: 100%;
      transform: scaleX(0);
      transition: transform 200ms ease, opacity 200ms ease;
    }

    &:hover,
    &:focus {
      color: inherit;

      & svg path {
        fill: currentColor;
      }

      &::after {
        transform: scaleX(1);
        opacity: 1;
      }
    }
  }
}

.no-js .SiteFooter {
  padding-top: 0;
  color: var(--colour-subtle);
}

/** pride flag at bottom
 *  ------------------------------------------------------------------------------------------------
**/
/* .SiteFooter__pride {
  margin-top: var(--bsu);
} */

.SiteFooter__pride {
  width: 100%;
  height: 0.5rem;
  margin-top: var(--bsu);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  /* this works but on chromium there are soft edges between colours... */
  /* background-image: linear-gradient(90deg, #844cf5 11.1%, #0088ff 11.1%, #0088ff 22.2%, #0ebf44 22.2%, #0ebf44 33.3%, #ffea26 33.3%, #ffea26 44.4%, #ff9012 44.4%, #ff9012 55.5%, #ed230c 55.5%, #ed230c 66.6%, #94581f 66.6%, #94581f 73.26%, #333333 73.26%, #333333 79.92%, #ffffff 79.92%, #ffffff 86.58%, #ffbaff 86.58%, #ffbaff 93.24%, #38cdff 93.24%, #38cdff 100%);
  background-size: 100%; */

  & span {
    display: block;
    height: 100%;
    flex: 1 0 auto;
  }

  & span:nth-child(1) {
    background: #844cf5;
    width: 11.1%;
  }

  & span:nth-child(2) {
    background: #0088ff;
    width: 11.1%;
  }

  & span:nth-child(3) {
    background: #0ebf44;
    width: 11.1%;
  }

  & span:nth-child(4) {
    background: #ffea26;
    width: 11.1%;
  }

  & span:nth-child(5) {
    background: #ff9012;
    width: 11.1%;
  }

  & span:nth-child(6) {
    background: #ed230c;
    width: 11.1%;
  }

  & span:nth-child(7) {
    background: #94581f;
    width: 6.66%;
  }

  & span:nth-child(8) {
    background: #333333;
    width: 6.66%;
  }

  & span:nth-child(9) {
    background: #ffffff;
    width: 6.66%;
  }

  & span:nth-child(10) {
    background: #ffbaff;
    width: 6.66%;
  }

  & span:nth-child(11) {
    background: #38cdff;
    width: 6.66%;
  }
}

/** backup nav for no-js mobile users
 *  ------------------------------------------------------------------------------------------------
**/
.SiteFooter__backup-nav {
  padding-bottom: var(--bsu-l);

  & ul {
    margin: 0;

    & li {
      display: block;
      width: 100%;
      background: currentColor;
      margin: 0;
    }
  }

  & a {
    display: block;
    width: 100%;
    padding: var(--bsu-s);
    /* stylelint-disable-next-line */
    color: var(--colour-primary) !important;

    &:hover,
    &:focus {
      /* stylelint-disable-next-line */
      color: var(--colour-invert) !important;
    }
  }
}

.js .SiteFooter__backup-nav {
  display: none;
}

@media (--column-break) {

  .SiteFooter__backup-nav {
    display: none;
  }

  .no-js .SiteFooter {
    padding-top: var(--bsu-l);
  }
}


/** Footer logo
 *  ------------------------------------------------------------------------------------------------
**/
.SiteFooter__logo {

  & a {
    display: inline-block;
    vertical-align: top;
    font-weight: bold;
    font-size: 1.4rem;
    letter-spacing: 2px;
  }

  /* & .Logo > .SvgWrap,
  & .Logo > img {
    width: 200px;
    max-width: 100%;
  } */
}


/** Footer nav
 *  ------------------------------------------------------------------------------------------------
**/
.SiteFooter__nav {
  --gap: var(--bsu-l);

  & ul {
    justify-content: center;
  }

  & li {
    display: block;
  }
}


/** Social icons
 *  ------------------------------------------------------------------------------------------------
**/
.SiteFooter__social {
  --gap: var(--bsu);

  & ul {
    justify-content: center;
  }

  & li {
    display: block;
  }

  & a {
    display: block;
    padding: var(--bsu-xxs);

    &:hover::after {
      content: none;
    }
  }
}

.SiteFooter__social .SvgWrap {
  width: var(--s1);
}

/** Footer back to top link
 *  ------------------------------------------------------------------------------------------------
**/
.SiteFooter__back-to-top.CTA {

  & .CTA__a {
    background-color: var(--colour-subtle);
    font-family: var(--ff-body);
    font-weight: normal;
    color: var(--colour-invert);
    font-size: var(--s-1);

    &:hover,
    &:focus {
      background-color: var(--colour-secondary);
    }
  }
}


/** Footer sub nav and colour mode selector
 *  ------------------------------------------------------------------------------------------------
**/
.SiteFooter__supplementary {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 0;
}

.SiteFooter__sub-nav,
.js--colour-mode-toggle {
  font-size: var(--s-2);
  padding: var(--bsu-l) var(--bsu) 0;
  margin: 0 auto;

  & ul {
    justify-content: center;
  }

  & li {
    display: block;
  }

  & a,
  & button {
    text-align: inherit;
  }
}

.ColourModeSelector button {
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
}

.ColourModeSelector .nav__item--active button {

  &::after {
    transform: scaleX(1);
    opacity: 1;
  }
}
