/** default link styling
 *  ------------------------------------------------------------------------------------------------
**/

a {
  color: var(--colour-secondary);
  text-decoration: none;
  transition: opacity var(--time-transition) var(--ease-default);

  &:hover,
  &:focus {
    color: var(--colour-highlight);
  }

  &:focus {
    outline: dashed 0.2rem currentColor;
    outline-offset: 0.2rem;
  }

  &:active {
    /* ... */
  }

  &:visited {
    /* ... */
  }

  &:link {
    /* highlights links on iOS, basically works like the :hover selector for mobile devices. */
    /* -webkit-tap-highlight-color: rgba(var(colour-secondary), 0.2); */
  }
}
