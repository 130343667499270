/** Custom styling that applies to the log in page and member's profile
 *  ------------------------------------------------------------------------------------------------
**/

/** Forgotten password button
 *  ------------------------------------------------------------------------------------------------
**/
.MemberProfile__cta-and-forgotten {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.MemberProfile__forgotten-password {
  text-align: left;

  /* left is to match input padding */
  padding: var(--bsu-s) 0 var(--bsu-s) var(--bsu-xs);

  --flow-space: var(--bsu-s);

  & button {
    cursor: pointer;
    background: transparent;
    border: 0;
    padding: 0 0 2px;
    font-size: var(--s-1);
    position: relative;
    transition: all var(--time-transition) var(--ease-default);
    transition-property: opacity;
    outline: 0;

    &::after {
      content: ' ';
      position: absolute;
      bottom: 0;
      left: 0;
      background: currentColor;
      height: 2px;
      width: 100%;
      transform: scaleX(0);
      opacity: 0;
      transition: all 200ms ease;
      transition-property: transform, opacity;
    }

    &:hover,
    &:focus {
      opacity: 0.9;

      /* &::before {
        transform: scaleX(1);
        opacity: 1;
      } */

      &::after {
        transform: scaleX(1);
        opacity: 1;
      }
    }

    &:focus {
      outline: dashed 0.2rem currentColor;
      outline-offset: 0.2rem;
    }
  }
}

/** CTA buttons in forms after you're logged in
 *  ------------------------------------------------------------------------------------------------
**/
.MemberProfile__content--logged-in {

  & .CTA button {
    padding: var(--bsu-xs) var(--bsu);
    font-size: var(--s-1);
  }

  & .CTA .Wrap {
    padding: 0;
  }
}
