/** site-wide loading indicator
 *  ------------------------------------------------------------------------------------------------
**/

.LoadingIndicator {
  display: block;
  visibility: hidden;
  height: 0;
  overflow: hidden;
  top: var(--bsu-xxs);
  left: 0;
  width: 100%;
  position: fixed;
  z-index: var(--z-loading-indicator);
  pointer-events: none;
  text-align: center;
  transition: visibility 0s 2s, height 0s 2s;

  & path {
    fill: currentColor;
  }
}

.LoadingIndicator.js--loading,
.js--loading .LoadingIndicator {
  visibility: visible;
  height: calc((var(--bsu-xxs) * 2) + var(--bsu));
  transition: visibility 0s, height 0s;
}

.LoadingIndicator__spinner {
  display: inline-block;
  position: relative;
  padding: var(--bsu-xxs);
  transform: scale(0);
  transition: transform var(--time-transition-fast) var(--ease-in-back);

  &::after {
    content: ' ';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--colour-background-body);
    border-radius: var(--bsu-s);
    opacity: 0.5;
  }
}

.js--loading .LoadingIndicator__spinner {
  transform: scale(1);
  transition: transform var(--time-transition-fast) var(--ease-out-back);
}

.LoadingIndicator__spinner .SvgWrap {
  position: relative;
  z-index: 2;
  display: block;
  margin: 0 auto;
  width: var(--bsu);
  height: var(--bsu);
  animation: spin 1.25s infinite;
  animation-timing-function: cubic-bezier(0.2, 0, 0.28, 1);
}

@keyframes spin {

  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}
