/** Header Text
 *  ------------------------------------------------------------------------------------------------
 *  consistent default spacing, sizing and alignment for pre/post/header text!
 *  we globally override header text styles at smaller sizes here too
**/

.HeaderText {
  text-align: center;
  position: relative;

  & p {
    /* larger font size for pre/post headers */
    font-size: var(--s1);
  }
}

.HeaderText__floating_id {
  position: absolute;
  pointer-events: none;
  top: calc(var(--spacing-vertical-headers) * -1);
  visibility: hidden;
}

.HeaderText__header {
  line-height: 1.3;
}

/* @media (--column-break) { */

.HeaderText--align-right {
  text-align: right;
}

.HeaderText--align-left {
  text-align: left;
}

/* } */


/** anchor links for headers with IDs
 *  ------------------------------------------------------------------------------------------------
**/
.HeaderText__anchor-link {
  position: absolute;
  top: -1rem;
  left: auto;
  opacity: 0;
  transition: all var(--time-transition) var(--ease-default);
  transition-property: opacity;
}

.HeaderText__anchor-link:focus {
  opacity: 1;
}

.HeaderText:hover .HeaderText__anchor-link {
  opacity: 1;
}

.HeaderText--align-right .HeaderText__anchor-link {
  margin-left: 0.25rem;
  top: 0;
}

.HeaderText--align-left .HeaderText__anchor-link {
  margin-left: -1rem;
  top: 0;
}
