/** toggle colour modes
 *  ------------------------------------------------------------------------------------------------
**/

.js--colour-mode-toggle {
  display: none;
}

.js .js--colour-mode-toggle {
  display: block;
}
