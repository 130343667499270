/** Quote Text
 *  ------------------------------------------------------------------------------------------------
 *  big bold blockquotes
**/

.QuoteText {
  text-align: center;

  & cite {
    font-style: normal;
    display: block;
  }
}

.QuoteText__quote {
  font-family: var(--ff-heading);
  font-weight: bold;
  font-size: var(--s3);
  font-size: 6vw;
}

.QuoteText--align-right {
  text-align: right;
}

.QuoteText--align-left {
  text-align: left;
}

@media (--column-break) {

  .QuoteText__quote {
    font-size: var(--s6);
  }
}
