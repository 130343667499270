/** CTA Buttons
 *  ------------------------------------------------------------------------------------------------
**/

.CTA {
  text-align: center;

  & .CTA__a {
    text-align: center;
    position: relative;
    display: inline-block;
    padding: var(--bsu-s) var(--bsu-l);
    vertical-align: top;
    background: currentColor;
    border: 2px solid currentColor;
    color: currentColor;
    border-radius: var(--radius-button);
    font-family: var(--ff-heading);
    outline: 0;
    cursor: pointer;
    transition: all var(--time-transition) var(--ease-default);
    transition-property: color, background-color, opacity, border-color;

    & .CTA__text {
      transition: color var(--time-transition) ease-in-out, opacity var(--time-transition-fast) var(--time-transition-fast) var(--ease-default);
      display: inline-block;
      vertical-align: top;
      padding: 3px 0;
      position: relative;

      &::after {
        content: ' ';
        position: absolute;
        bottom: 0;
        left: 0;
        background: currentColor;
        height: 2px;
        width: 100%;
        transform: scaleX(0);
        transition: transform 200ms ease, opacity 200ms ease;
      }
    }

    &.CTA__a.CTA__a:hover,
    &.CTA__a.CTA__a:focus {
      /* background: transparent;
      border-color: transparent; */
      opacity: 0.9;

      & span {
        /* color: currentColor; */

        /* stylelint-disable-next-line max-nesting-depth */
        &::after {
          transform: scaleX(1);
          opacity: 1;
        }
      }
    }

    &.CTA__a.CTA__a:focus {
      outline: dashed 0.2rem currentColor;
      outline-offset: 0.2rem;
    }
  }
}

/* @media (--column-break) { */

.CTA--align-right {
  text-align: right;
}

.CTA--align-left {
  text-align: left;
}

/* } */


/** loading
 *  ------------------------------------------------------------------------------------------------
**/
.CTA__loader {
  position: absolute;
  width: 100%;
  pointer-events: none;
  visibility: hidden;
  height: 0;
  top: 0;
  left: 0;
  overflow: hidden;
  padding: var(--bsu-xxs);
  transition: visibility 0s 2s, height 0s 2s;

  & path {
    fill: currentColor;
  }
}

.CTA__spinner {
  display: block;
  width: 100%;
  height: 100%;
  transform: scale(0);
  transition: transform var(--time-transition-fast) var(--ease-in-back);
}

.CTA__spinner .SvgWrap {
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  animation: spin 1.25s infinite;
  animation-timing-function: cubic-bezier(0.2, 0, 0.28, 1);
}

@keyframes spin {

  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

.CTA.js--loading,
.CTA__a.js--loading {

  & .CTA__loader {
    visibility: visible;
    height: 100%;
    transition: visibility 0s, height 0s;
  }

  & .CTA__spinner {
    transform: scale(1);
    transition: transform var(--time-transition-fast) var(--ease-out-back);
  }

  & .CTA__text {
    opacity: 0;
    transition: transform var(--time-transition-fast) var(--ease-default);
  }
}


/** new tab notices
 *  ------------------------------------------------------------------------------------------------
**/
.CTA__new-tab-notice {
  position: absolute;
  padding-top: var(--bsu-xxs);
  width: 100%;
  text-align: center;
  top: 100%;
  left: 0;
  font-size: var(--s-2);
  opacity: 0;
  pointer-events: none;
  transition: all var(--time-transition) var(--ease-default);
  transition-property: opacity;
}

.CTA__new-tab-icon {
  position: absolute;
  height: 0.8rem;
  width: 0.8rem;
  top: 50%;
  right: var(--bsu-s);
  margin-top: -0.4rem;
  opacity: 0.5;

  & path {
    fill: currentColor;
  }
}

.CTA__a:hover .CTA__new-tab-notice,
.CTA__a:focus .CTA__new-tab-notice {
  opacity: 1;
}
