/*! modification of normalize.css v8.0.1 | github.com/necolas/normalize.css */

/** normalising
 *  ------------------------------------------------------------------------------------------------
 *  we don't need everything from normalize.css - particularly things that we already reset
 *  explicitly in one of our other starter files.
 *  this is a small subset of normalize that I use the most.
 *  feel free to swap in the whole thing!
**/

/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 */
html {
  line-height: 1.15; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

/**
 * Render the `main` element consistently in IE.
 */
main {
  display: block;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre,
code,
kbd,
samp {
  /* stylelint-disable-next-line font-family-no-duplicate-names */
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Remove the gray background on active links in IE 10.
 */
a {
  background-color: transparent;
}

/**
 * 1. Remove the bottom border in Chrome 57-
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: 0; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/**
 * Remove the border on images inside links in IE 10.
 */
img {
  border-style: 0;
}
