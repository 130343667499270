/** NotificationList
 *  ------------------------------------------------------------------------------------------------
 *  displays a list of dismissable notifications...
**/

.NotificationList {
  text-align: center;
  padding: 0 var(--bsu-xs);

  & ul {
    list-style: none;
  }
}

.NotificationList__notification button {
  display: inline-block;
  padding: var(--bsu-s);
  padding-right: var(--bsu);
  position: relative;
  background: var(--colour-background-body);
  border-radius: var(--bsu-xs);
  box-shadow: var(--box-shadow-default);
  border: 0.3rem solid;
  cursor: pointer;

  &:focus {
    outline: dashed 0.2rem currentColor;
    outline-offset: 0.2rem;
  }
}

.NotificationList__notification button .SvgWrap {
  width: var(--bsu-s);

  & path {
    fill: var(--colour-background-body);
  }
}

.NotificationList__notification__close {
  background: currentColor;
  padding: var(--bsu-xs);
  position: absolute;
  right: -0.6rem;
  top: -0.6rem;
  margin-top: 0;
  border-radius: var(--bsu-xs);
  transition: transform var(--time-transition-snap) var(--ease-default);
}

.NotificationList__notification button:hover,
.NotificationList__notification button:focus {

  & .NotificationList__notification__close {
    transform: scale(1.05);

    &:hover {
      transform: scale(1.1);
    }
  }
}


.body--with-default-background .NotificationList__notification button {
  color: currentColor;
  background: currentColor;
}

.body--with-default-background .NotificationList__notification .RichText,
.body--with-default-background .NotificationList__notification .HeaderText {
  color: var(--colour-background-body);
}


/** Global notifications
 *  ------------------------------------------------------------------------------------------------
 *  There's some special rules for global notifications
**/
.js--GlobalNotifications {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: var(--z-notifications);

  & .NotificationList {
    padding: var(--bsu-xs) var(--bsu);
  }

  & button {
    width: 100%;
  }
}


/** Disposable inline form notifications
 *  ------------------------------------------------------------------------------------------------
**/
.js--notifications .NotificationList {
  margin-top: var(--bsu);
}
