/** @font-face imports should happen here!
 *  ------------------------------------------------------------------------------------------------
 *  however we are currently loading the main font for this site (Poppins) through Google Fonts
 *  it's loaded using techniques from https://csswizardry.com/2020/05/the-fastest-google-fonts/
 *  in the site head
**/


/** font families
 *  ------------------------------------------------------------------------------------------------
**/
:root {
  /*
  these are quite nice default families.
  but lately I prefer just using device fonts as the fallbacks...
  -
  --sans-serif: Helmet, Freesans, Tahoma, Geneva, sans-serif;
  --serif: Georgia, Cambria, 'Times New Roman', Times, serif;
  */
  --sans-serif: sans-serif;
  --serif: serif;


  /** Site font variables
  *  ------------------------------------------------------------------------------------------------
  **/
  --ff-poppins: 'Poppins', var(--sans-serif);

  /*
  the named font variables above aren't used directly
  instead we distinguish between the main (body) font and heading fonts
  */
  --ff-body: var(--ff-poppins);
  --ff-heading: var(--ff-poppins);
}
