/** .ContentSection
 *  ------------------------------------------------------------------------------------------------
 *  every block of content that shares spacing etc.
**/

.ContentSection {
  padding: var(--section-padding) 0;
  position: relative;
  z-index: var(--z-sections);

  /* centralise in vertical space */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ContentSection--back-secondary {
  color: var(--colour-invert);
  background-color: var(--colour-subtler);
}

.ContentSection--with-back {
  color: var(--colour-invert);
}

.ContentSection--no-back {
  min-height: 0;
  padding: var(--bsu-l) 0;
}


/** If our content contains an immediate wrap we disable padding on mobile sizes
 *  ------------------------------------------------------------------------------------------------
**/
.ContentSection__content > .Wrap {
  padding-left: 0;
  padding-right: 0;
}

@media (--edge-to-edge-ends) {

  .ContentSection__content > .Wrap {
    padding-left: var(--bsu);
    padding-right: var(--bsu);
  }
}


/** This class is for content that's meant to trail the section above.
 *  ------------------------------------------------------------------------------------------------
 *  min-height and padding are dropped...
**/
.ContentSection--top-collapse {
  padding-top: 0;
  min-height: 0;
}

.ContentSection__content {
  z-index: 3;
  position: relative;
}


/** Content sections on pages with fading section backgrounds (i.e. the home page)
 *  ------------------------------------------------------------------------------------------------
**/
.site-main--unique-sections .ContentSection {
  padding: var(--section-padding) 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  /* colours */
  background: currentColor;
}

.got-intersection .site-main--unique-sections .ContentSection {
  background: transparent;
}

@media (--menu-floats) {

  .site-main--unique-sections .ContentSection {
    padding-top: var(--section-padding);
    padding-bottom: var(--section-padding);
  }

  @supports (transform: rotate(-90deg) translateX(-50%) translateY(0%)) {

    .site-main--unique-sections .ContentSection--with-title {
      padding-left: 15vw;
      padding-left: var(--spacing-vertical-headers);
    }
  }
}
