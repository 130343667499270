/** Markdown text
 *  ------------------------------------------------------------------------------------------------
 *  we don't add very much to it!
**/

.RichText {
  text-align: center;

  & img {
    margin-left: auto;
    margin-right: auto;
  }
}

/** links in rich text
 *  ------------------------------------------------------------------------------------------------
**/
.RichText a,
.RichText a:visited {
  color: inherit;
  position: relative;
  transition: all var(--time-transition) var(--ease-default);
  transition-property: opacity;
  outline: 0;
  text-decoration: underline;
  text-decoration-thickness: 0.12em;
  text-underline-offset: 0.12em;

  /* font-weight: bold; */

  /* &::after {
    content: ' ';
    position: absolute;
    bottom: 0;
    left: 0;
    background: currentColor;
    height: 2px;
    width: 100%;
    transform: scaleX(1);
    transition: all 200ms ease;
    transition-property: transform, opacity;
  } */

  /* complimentary colour shift on hover */
  /* &::before {
    content: ' ';
    position: absolute;
    width: 102%;
    height: 100%;
    top: 0;
    left: -1%;
    z-index: 1;
    backdrop-filter: hue-rotate(90deg);
    border-radius: 0.5rem;
    transform-origin: bottom center;
    opacity: 0;
    transition: all var(--time-transition) var(--ease-default);
    transition-property: transform, opacity;
  } */

  &:hover,
  &:focus {
    opacity: 0.8;

    /* &::before {
      transform: scaleX(1);
      opacity: 1;
    } */

    /* &::after {
      transform: scaleX(0);
      opacity: 1;
    } */
  }

  &:focus {
    outline: dashed 0.2rem currentColor;
    outline-offset: 0.2rem;
  }
}

/* @media (--column-break) { */

.RichText--align-right {
  text-align: right;

  & img {
    margin-right: 0;
    margin-left: auto;
  }
}

.RichText--align-left {
  text-align: left;

  & img {
    margin-left: 0;
    margin-right: auto;
  }
}

/* } */
