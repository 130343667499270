/** Visibility Helpers
 *  ------------------------------------------------------------------------------------------------
 *  for hiding things
 *  or hiding things visually that you still want screenreaders to see
**/

.hidden {
  display: none;
  visibility: hidden;
}

/* https://allyjs.io/tutorials/hiding-elements.html#2017-edition-of-visuallyhidden */
.visuallyhidden:not(:focus):not(:active) {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.visuallyhidden--always {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

/* Hide visually and from screen readers, but maintain layout */
.invisible {
  visibility: hidden;
}
