/** Home page styles
 *  ------------------------------------------------------------------------------------------------
 *  the fading sections and individual section titles on the home page are styled in
 *  ContentSection.css
**/
.Home-splash .ContentSection,
.js .Home-splash .ContentSection {
  padding: var(--bsu-l) 0;
  overflow: hidden;
}

/* .js .Home-splash {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 99999;
}

.HomeSplash--scrolled {
  opacity: 0;
  pointer-events: none;
  transition: opacity var(--time-transition) var(--ease-default);
} */

@media (--column-break) {

  .js .Home-splash .ContentSection {
    /* background: var(--colour-invert); */
  }
}

.Home-splash__down {
  text-align: center;
  padding: var(--bsu-l);
  width: 100%;
  text-transform: uppercase;
  opacity: 0;
  transform: translateY(-50%);
  transition: all var(--time-transition) var(--ease-default);
  transition-property: opacity, transform;

  & a {
    position: relative;
    display: inline-block;
    animation-name: color_change;
    animation-duration: 20s;
    animation-iteration-count: infinite;
    animation-direction: alternate;

    & .SvgWrap {
      width: var(--bsu);
      opacity: 0.75;
    }

    /* & svg {
      animation-name: floating;
      animation-duration: 4s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
    } */

    & svg {
      transition: all var(--time-transition) var(--ease-default);
      transition-property: opacity, transform;
    }

    & path {
      fill: currentColor;
    }
  }
}

@media (--column-break) {

  .Home-splash__down {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: var(--bsu);
    width: 100%;

    & a {
      /* &::after,
      &::before {
        content: ' ';
        position: absolute;
        z-index: -2;
        top: 50%;
        left: 50%;
        width: 1vmax;
        height: 1vmax;
        background: currentColor;
        border-radius: 100%;
        transform: translateX(-50%) translateY(-50%);
        pointer-events: none;
        opacity: 0;
      }

      &::before {
        z-index: -1;
        background: var(--colour-invert);
      } */

      &:hover,
      &:focus {
        /* &::after {
          transform: translateX(-50%) translateY(-50%) scale(200);
          opacity: 0.5;
          transition: opacity 200ms ease, transform 1200ms cubic-bezier(0.85, 0, 0.15, 1);
        }

        &::before {
          transform: translateX(-50%) translateY(-50%) scale(200);
          opacity: 1;
          transition: opacity 200ms 700ms ease;
        } */

        & svg {
          transform: translateY(-25%);
          opacity: 0.75;
        }
      }
    }
  }
}

.body-surrogate.colour--homesplash .Home-splash__down,
.no-js .Home-splash__down {
  opacity: 1;
  transform: translateY(0);
}

.gladd-splash-logo {
  padding: 0 var(--bsu);
  text-align: center;
  max-width: 100%;
  overflow: hidden;
}

/** Asclepius and logo text
 *  ------------------------------------------------------------------------------------------------
**/
.gladd-splash-logo__asclepius {
  /*
    the height padding on this logo is 137.53915222384632%

    to get the logo to be a set number of vh tall we need to do:
    width = ((heightVH / 137.53915222384632) * 100)vh
  */
  width: 300px;
  width: 24vh; /* using above formula gives us a 33vh asclepius */
  width: min(24vh, 50vw);
  max-width: 100%;
  margin: 0 auto;

  & .staff {
    fill: var(--colour-primary);
  }

  & svg {
    left: 2%; /* centralised over the A */
  }
}

.gladd-splash-logo__gladd h1 {
  font-size: 10vh;
  font-size: min(10vh, 20vw);
  line-height: 1.1;
  color: var(--colour-primary);
}

.gladd-splash-logo__tagline p {
  font-size: 2.5vh;
  font-size: min(2.5vh, 5vw);
  color: var(--colour-primary);
}

.body--with-colour-background.body-surrogate .Home-splash.Home-splash {
  /* these are special cases for colour... */
  & .colour--red a {
    color: var(--colour-default-foreground-red);
  }

  & .colour--yellow a {
    color: var(--colour-default-foreground-yellow);
  }

  & .colour--green a {
    color: var(--colour-default-foreground-green);
  }

  & .colour--teal a {
    color: var(--colour-default-foreground-teal);
  }

  & .colour--blue a {
    color: var(--colour-default-foreground-blue);
  }

  & .colour--pink a,
  & .colour--pink .MembershipLinks__sub-link {
    color: var(--colour-default-foreground-pink);
  }

  & .colour--purple a {
    color: var(--colour-default-foreground-purple);
  }

  & .CTA__a .use-colour--foreground {
    color: var(--colour-invert);
  }

  & .CTA__a {
    background: currentColor;
  }
}


/** scroll down colour change
 *  ------------------------------------------------------------------------------------------------
**/

@keyframes color_change {

  0% { color: var(--colour-default-foreground-red); }

  16% { color: var(--colour-default-foreground-yellow); }

  32% { color: var(--colour-default-foreground-green); }

  48% { color: var(--colour-default-foreground-teal); }

  64% { color: var(--colour-default-foreground-pink); }

  80% { color: var(--colour-default-foreground-purple); }

  100% { color: var(--colour-default-foreground-blue); }
}

@keyframes floating {

  from { transform: translate(0, 0); }

  32.5% { transform: translate(0, 15px); }

  50% { transform: translate(0, 0); }
}
