.VerticalHeader {
  text-transform: uppercase;
  padding: var(--bsu-l) 0 0;

  & p {
    font-size: 3vw;
    font-size: clamp(1rem, 3vw, 1.5rem);
    text-align: left;
  }

  & h1 {
    font-size: 7.5vw;
    font-size: clamp(2rem, 7.5vw, 3rem);
    text-align: left;
  }
}

/* these are the ones on the home page */
.ContentSection .VerticalHeader {
  padding: var(--bsu) 0 var(--bsu-l);
}

/* .body--with-colour-background .VerticalHeader .HeaderText {
  color: var(--colour-invert);
} */

@media (--menu-floats) {

  @supports (transform: rotate(-90deg) translateX(-50%) translateY(0%)) {
    /*
      this initial set of styles puts the vertical header at the top.
      Means that no-js users get a vertical header that still sort of looks nice!
    */
    .VerticalHeader {
      position: absolute;
      top: 0;
      left: 0;
      transform: rotate(-90deg) translateX(-100%) translateY(0%);
      transform-origin: top left;
      padding: var(--bsu) var(--bsu-xl);

      & p {
        font-size: 2vw;
        font-size: clamp(0.5rem, 2vw, 1rem);
        text-align: center;
      }

      & h1 {
        font-size: 4vw;
        font-size: clamp(1rem, 4vw, 2.5rem);
        text-align: center;
      }
    }

    .site-main--home .VerticalHeader {
      top: 50%;
      transform: rotate(-90deg) translateX(-50%) translateY(0%);
      padding: var(--bsu) var(--bsu-l);
    }

    .js .site-main:not(.site-main--home) .VerticalHeader {
      opacity: 0;
      transition: opacity var(--time-transition) var(--ease-default);

      /*
        JS version gets some sizing updates and spans whole screen height.
        Internal font size is determined by JS if it's too big...
      */
      position: fixed;
      z-index: 1;
      width: 100vh;
      white-space: nowrap;
      top: 50%;
      transform: rotate(-90deg) translateX(-50%) translateY(0%);
      transform-origin: top left;

      /* we move all the padding to the top to offset header... */
      padding: var(--bsu) calc(var(--bsu-xl) + (var(--bsu-xl * 0.5))) var(--bsu) calc((var(--bsu-xl * 0.5)));

      & span {
        display: inline-block;
      }
    }

    .js .site-main .VerticalHeader.js--sized {
      opacity: 1;
    }
  }
}
