/** global or base site css
 *  ------------------------------------------------------------------------------------------------
 *  Here's where you can put all of your site specific code that should apply globally or isn't
 *  specific to any components etc.
**/

/* scale fonts based on screen size - included as an example - remove if you want! */
@import '_scale-fonts.css';

/** body defaults
 *  ------------------------------------------------------------------------------------------------
**/
body {
  line-height: 1.5;
  font-family: var(--ff-body);
  color: var(--colour-invert);
  background-color: var(--colour-background-body);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all var(--time-transition) var(--ease-default);
  transition-property: color, background-color, opacity;

  /*
    Sometimes required for skinny fonts - particularly for light on dark text.
    Only applies on Mac OS.
  */
  /*
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  */
}


.body--with-colour-background {
  /* background-color: currentColor; */
  transition: all var(--time-transition) var(--ease-default);
  transition-property: color, background-color;
}

/** lock scrolling if popups are active
 *  ------------------------------------------------------------------------------------------------
**/
.js--has-popup {
  height: 100%;
  height: 100vh;
  overflow: hidden;
}

/** main content sizing and space for vertical titles
 *  ------------------------------------------------------------------------------------------------
**/
.site-main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  outline: 0; /* it's focusable because it has tabindex to allow us to skip to it */
  padding-top: var(--bsu-xl);
  padding-bottom: var(--section-padding);

  /* transition: color var(--time-transition) var(--ease-default); */
}

/* transition to fade background colours etc. */
.site-main--unique-sections {
  /* background: currentColor; */ /* Safari does not transition this well at all */
  /*
    instead of currentColor background here we now use `use-background--background` class
    which directly sets colours. This is due to poor currentColor support in Safari.
    It transitions very poorly
  */
  padding: 0;
}

.js .site-main--unique-sections {
  /* color: var(--colour-primary); */
}

@media (--menu-floats) {

  @supports (transform: rotate(-90deg) translateX(-50%) translateY(0%)) {

    .site-main {
      padding-left: 15vw;
      padding-left: var(--spacing-vertical-headers);
    }
  }

  /* unique-sections moves padding and sizing into the ContentSection(s) */
  .site-main--unique-sections {
    padding: 0;
  }
}


@media (--column-break) {
  /* this is where the nav menu starts displaying sticky */
  .site-main {
    padding-top: var(--bsu);
    padding-bottom: var(--section-padding);
  }

  /* unique-sections moves padding and sizing into the ContentSection(s) */
  .site-main--unique-sections {
    padding: 0;
  }
}
