/** Layouts
 *  ------------------------------------------------------------------------------------------------
 *  -  max widths for various situations!
 *  -  specific widths to do with layouts
**/

:root {
  --layout-max: 125rem;
  --layout-columns: 90rem;/* for double columns - 1440px-ish */
  --layout-content: 60rem; /* for single columns - 960px @ 16px */
  --layout-readable: 40rem; /* for text content - 640px @ 16px */
  --layout-inputs: 25rem; /* for single stacked inputs - 400px @ 16px */

  /** column widths
   *  ----------------------------------------------------------------------------------------------
  **/
  --column-light: 35%;
  --column-heavy: 65%;

  /** spacing for vertical headers
   *  ----------------------------------------------------------------------------------------------
  **/
  --spacing-vertical-headers: min(15vw, 150px);
}
