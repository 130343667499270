/** A wrap to position and pad SVG elements
 *  ------------------------------------------------------------------------------------------------
**/

.SvgWrap {
  position: relative;

  & svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
